// 店舗一覧取得API（/get-store-list/all）の呼び出し
import * as React from "react";
import { useEffect, useState } from "react";
import TableBoard from "../molecules/TableBoard";
import StoreDto from "../../features/dto/StoreDto";
import { StoreDisplayObj } from "../../features/objects/StoreDisplayObj";
import axios from "axios";

interface StoreSearchProps {
  dataTransportFunc: (data: object) => void;
}

export default function StoreSearch({ dataTransportFunc }: StoreSearchProps): JSX.Element {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  const apiUrl = "/get-store-list/all";
  // 表示カラムは"店舗ID","店舗名", "店舗位置情報 (ID)", "エリア","ジャンル"
  const columns: string[] = [
    "店舗ID",
    "店舗名",
    "店舗位置情報 (ID)",
    "エリア",
    "ジャンル"
  ];
  
  const [data, setData] = useState<StoreDisplayObj[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
  const [errorStatus, setErrorStatus] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get(apiUrl, {
        params: {
          limit: 50
        },
        timeout: 30000
      })
      .then((res) => {
        const resultItems: StoreDto[] = res.data.result_content;
        setData(createDisplayData(resultItems));
        console.log("データ取得成功");
      })
      .catch((err) => {
        setErrorStatus(true);
        console.log(err);
        console.log("データ取得エラー");
        if (err.response !== undefined) {
          console.log(err.response);
        } else if (err.request !== undefined) {
          console.log(err.request);
        } else {
          console.log(err.message);
        }
      })
      .finally(() => {
        setLoadingStatus(false);
      });
  }, []);
  console.log(data);

  const createDisplayData = (items: StoreDto[]): StoreDisplayObj[] => {
    const displayData: StoreDisplayObj[] = [];
    items.map((data: StoreDto) =>
      displayData.push({
        store_id: data.store_id,
        store_name: data.store_name,
        site_id: data.site_id,
        area: data.area,
        genre: data.genre
      })
    );
    return displayData;
  };

  return (
    <>
      <TableBoard
        title="店舗一覧"
        data={data}
        columnNames={columns}
        loadingStatus={loadingStatus}
        errorStatus={errorStatus}
        dataTransportFunc={dataTransportFunc}
      />
    </>
  );
}