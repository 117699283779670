import axios from 'axios';
import { ProductObj } from '../../../features/objects/ProductObj'
import { ProductInfoDto, ProductImageDto } from '../../../features/dto/ProductDto';

export const fetchProductsAll = async () => {
    // APIのエンドポイント
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    const apiUrl = "/get-product-list/all";
    return await axios.get(apiUrl, {params: {}, timeout: 30000})
}

export const fetchProductsInfo = async (offset: number=0, limit: number=100) => {
    // APIのエンドポイント
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    const apiUrl = "/get-product-list/info";
    return await axios.get(apiUrl, {params: {offset: offset, limit: limit}, timeout: 30000})
}

export const fetchProductsImage = async (product_ids: number[]) => {
    // APIのエンドポイント
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    const apiUrl = "/get-product-list/image";
    return await axios.get(apiUrl, {params: {product_ids},
        paramsSerializer: params => {
        return Object.keys(params)
            .map(key => {
                return params[key].map((value: number) => `${key}=${value}`).join('&');
            }).join('&');
    }, timeout: 30000})
}

export const mergeProductsAndImages = (
    products: ProductInfoDto[],
    productImages: ProductImageDto[]
    ): ProductObj[] => {
  const productMap = new Map<number, ProductObj>();

  // ProductDtoをマップに格納
  products.forEach(product => {
      productMap.set(product.product_id, { 
          product_id: product.product_id, 
          product_name: product.product_name, 
          store_name: product.store_name, 
          product_price: product.product_price, 
          product_image: '' // 初期値を空にしておく
      });
  });

  // ProductImageDtoをマップから探して結合
  productImages.forEach(productImage => {
      if (productMap.has(productImage.product_id)) {
          const product = productMap.get(productImage.product_id);
          if (product) {
              product.product_image = productImage.product_image; // 画像を追加
          }
      }
  });

    // 結果を配列に変換
    return Array.from(productMap.values());
  };