import React, { useEffect, useState } from "react";
import { ProductObj } from '../../../features/objects/ProductObj'
import { ProductImageDto } from '../../../features/dto/ProductDto';
import '../../../assets/css/ProductList.css';
import {fetchProductsAll, fetchProductsInfo, fetchProductsImage, mergeProductsAndImages} from './ProductApi';
import { useNavigate } from 'react-router-dom';


const ProductListDemo: React.FC = (): JSX.Element => {
  const [products, setProducts] = useState<ProductObj[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);  
  const navigate = useNavigate();
  let offset = 0;
  const limit = 100;

  useEffect(() => {
    const loadProducts = async () => {      
      try{
        const products = await fetchProductsAll();
        setProducts(products.data.result_content);
      } catch(error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    }
    loadProducts();
  }, []);
  
  const handleOrderClick = (product: ProductObj) => {
    // 商品情報を状態として渡して /Order へ遷移
    navigate('/order', { state: { product } });
    
  if (loading) {
    return <div>Loading...</div>;
  }
};

  return (
    <div className="product-list">
      {products.map((product: ProductObj) => (
        <div key={product.product_id} className="product-card">
          <img src={`data:image/jpeg;base64,${product.product_image}`} alt={product.product_name} className="product-image" />
          <h2 className="product-name">{product.product_name}</h2>
          <p className="product-store">{product.store_name}</p>
          <p className="product-price">{Intl.NumberFormat('ja-JP').format(product.product_price)}円</p>
          <button onClick={() => handleOrderClick(product)}>注文</button>
        </div>
      ))}
    </div>
  );
};
export default ProductListDemo;