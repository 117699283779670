import * as React from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import DefaultTemplate from "../templates/DefaultTemplate";
import BrowserTab from "../organisms/BrowserTab";
import PageTitle from "../organisms/PageTitle";

export default function OrderCompletion(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const { order_no } = location.state as { order_no: number }; // 受け取った商品情報
  const pageTitle = "注文受付完了";

  const handleOrderClick = () => {
    navigate('/');
  }

  return (
    <>
      <BrowserTab title={pageTitle} />
      <DefaultTemplate>
        <PageTitle title={pageTitle} />
        <div>
            <h1>Order Page</h1>
            <h2>{order_no}</h2>
            <button onClick={() => handleOrderClick()}>Top</button>
        </div>
      </DefaultTemplate>
    </>
  );
}
