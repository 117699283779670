import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import DefaultTemplate from "../templates/DefaultTemplate";
import BrowserTab from "../organisms/BrowserTab";

export default function Top(): JSX.Element {
  const pageTitle = "トップ";
  return (
    <>
      <BrowserTab title={pageTitle} />
      <DefaultTemplate>
        <Box sx={{ my: 2 }}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Link href="/what">What?</Link>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Link href="/how">How?</Link>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Link href="/where">Where?</Link>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Link href="/sdgs">SDGs</Link>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Link href="/contact">Contact</Link>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Link href="/products">Products</Link>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Link href="/productsDemo">ProductsDemo</Link>
            </Grid>
            {/*restaurantsの項目を追加*/}
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Link href="/restaurants">Restaurants</Link>
            </Grid>
          </Grid>
        </Box>
      </DefaultTemplate>
    </>
  );
}
