import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import DefaultTemplate from "../templates/DefaultTemplate";
import BrowserTab from "../organisms/BrowserTab";
import PageTitle from "../organisms/PageTitle";
import StoreSearch from "../organisms/StoreSearch";

// sdgs.tsxを参考にしてRestaurantsを定義
// returnではストア検索コンポーネントをレンダリングし、データが選択された時のハンドラーを設定
export default function Restaurants(): JSX.Element {
    const pageTitle = "店舗検索";
    
    const [data, setData] = useState<object>([]);
    
    const dataSelectedHandler = (data: object): void => {
        setData(data);
    };
    
    return (
        <>
        <BrowserTab title={pageTitle} />
        <DefaultTemplate>
            <PageTitle title={pageTitle} />
            <Box sx={{ my: 4 }}>
            <StoreSearch dataTransportFunc={dataSelectedHandler} />
            </Box>
        </DefaultTemplate>
        </>
    );

}