import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Top from "./components/pages/Top";
import What from "./components/pages/What";
import How from "./components/pages/How";
import Where from "./components/pages/Where";
import Sdgs from "./components/pages/Sdgs";
import Contact from "./components/pages/Contact";
import Products from "./components/pages/Products";
import ProductsDemo from "./components/pages/ProductsDemo";
import Order from "./components/pages/Order";
import OrderComplete from "./components/pages/OrderComplete";
//restaurantsの定義を追加
import Restaurants from "./components/pages/Restaurants";

export default function App(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Top />} />
        <Route path="/what" element={<What />} />
        <Route path="/how" element={<How />} />
        <Route path="/where" element={<Where />} />
        <Route path="/sdgs" element={<Sdgs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/products" element={<Products />} />
        <Route path="/productsDemo" element={<ProductsDemo />} />
        <Route path="/order" element={<Order />} />
        <Route path="/orderComplete" element={<OrderComplete />} />
        {/*restaurantsの定義を追加*/}
        <Route path="/restaurants" element={<Restaurants />} />
      </Routes>
    </BrowserRouter>
  );
}
