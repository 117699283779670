import * as React from "react";
import { useState } from "react";
import DefaultTemplate from "../templates/DefaultTemplate";
import BrowserTab from "../organisms/BrowserTab";
import PageTitle from "../organisms/PageTitle";
import ProductList from "../atoms/Order/ProductList";

export default function Products(): JSX.Element {
  const pageTitle = "商品一覧";

  const [data, setData] = useState<object>([]);

  const dataSelectedHandler = (data: object): void => {
    setData(data);
  };
  return (
    <>
      <BrowserTab title={pageTitle} />
      <DefaultTemplate>
        <PageTitle title={pageTitle} />
        <ProductList />
      </DefaultTemplate>
    </>
  );
}
