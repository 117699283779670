import * as React from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import DefaultTemplate from "../templates/DefaultTemplate";
import BrowserTab from "../organisms/BrowserTab";
import PageTitle from "../organisms/PageTitle";
import { ProductObj } from '../../features/objects/ProductObj'
import {order} from '../atoms/Order/OrderApi';


export default function Order(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const { product } = location.state as { product: ProductObj }; // 受け取った商品情報
  const pageTitle = "注文";

  const handleOrderClick = async (product: ProductObj) => {
    // 商品情報を状態として渡して /Order へ遷移
    const order_no = product.product_id, order_num = 1;
    try{
      const order_result = await order(order_no, order_num);
    } catch(error) {
      console.error('Order Error:', error);
    }
    navigate('/orderComplete', { state: { order_no } });
  }

  return (
    <>
      <BrowserTab title={pageTitle} />
      <DefaultTemplate>
        <PageTitle title={pageTitle} />
        <div>
            <h1>Order Page</h1>
            <h2>{product.product_name}</h2>
            <p>店舗: {product.store_name}</p>
            <p>価格: {Intl.NumberFormat('ja-JP').format(product.product_price)}円</p>
            <img 
              src={`data:image/jpeg;base64,${product.product_image}`} 
              alt={product.product_name} 
              style={{ width: '80%', height: 'auto' }}
            />
           <br /> 
            <button onClick={() => handleOrderClick(product)} style={{ marginTop: '10px' }}>注文確定</button>
        </div>
      </DefaultTemplate>
    </>
  );
}
